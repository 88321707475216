export const minTuitionFinanced = 501;

export const US_STATES = [
  "Alabama",
  "Alaska",
  "Arizona",
  "Arkansas",
  "California",
  "Colorado",
  "Connecticut",
  "Delaware",
  "Florida",
  "Georgia",
  "Hawaii",
  "Idaho",
  "Illinois",
  "Indiana",
  "Iowa",
  "Kansas",
  "Kentucky",
  "Louisiana",
  "Maine",
  "Maryland",
  "Massachusetts",
  "Michigan",
  "Minnesota",
  "Mississippi",
  "Missouri",
  "Montana",
  "Nebraska",
  "Nevada",
  "New Hampshire",
  "New Jersey",
  "New Mexico",
  "New York",
  "North Carolina",
  "North Dakota",
  "Ohio",
  "Oklahoma",
  "Oregon",
  "Pennsylvania",
  "Rhode Island",
  "South Carolina",
  "South Dakota",
  "Tennessee",
  "Texas",
  "Utah",
  "Vermont",
  "Virginia",
  "Washington",
  "West Virginia",
  "Wisconsin",
  "Wyoming"
];

export const fake_schools = [
  "Abraham Lincoln University",
  "Academia School of Nursing",
  "Academy for Nursing and Health Occupations",
  "Academy of Careers and Technology",
  "Acaydia School of Aesthetics",
  "Acupuncture and Massage College",
  "Advance Welding Institute",
  "Advanced Training Associates",
  "Affordable Truck Driver Training",
  "Alabama Fire College",
  "Alamo Colleges",
  "Albuquerque Fire Department",
  "Allen County Community College",
  "Allied Health Training Center",
  "Alvin Community College",
  "American Academy of Professional Coders",
  "American College of Education",
  "American Gunsmithing Institute",
  "American Institute Holdings, Inc.",
  "American Institute of Medical Sciences & Education",
  "American Medical Sciences Center",
  "American Training school for Medical Professionals",
  "Ameritech College of Healthcare",
  "AnimSchool",
  "Antelope Valley College",
  "Apollo Career Center",
  "Arbuckle Truck Driving School",
  "Arizona Automotive Institute",
  "Arizona Western College",
  "Arkansas Welding Academy",
  "Armstrong State University",
  "Ashona Medical Institute",
  "Aspire Medical Training Academy",
  "Atelier Esthetique Institute of Esthetics",
  "Atlanta Technical College",
  "Augusta University Dietetic Internship",
  "Automotive Training Center",
  "Averett University",
  "Ayers Career College",
  "Baker College of Owosso",
  "Baldy View ROP",
  "Baptist Health System School of Health Professions",
  "Baruch College",
  "Bay State School of Technology",
  "Beaufort County Community College",
  "Becket Training & Consulting",
  "Belanger School of Nursing at Ellis Medicine",
  "Bellin College - Bellin Health School of Radiologic Technology",
  "Bentley University",
  "Berkeley City College",
  "Bethesda College of Health Sciences",
  "Bismarck State College",
  "Blessing Hospital",
  "Blue Cliff College",
  "Blue Ridge CTC",
  "BOCES - Cayuga-Onondaga",
  "BOCES - Orange-Ulster",
  "Bon Secours Memorial College of Nursing",
  "Bradford Regional Medical Center",
  "Bridgeport Hospital - Fairfield University",
  "Brookhaven College",
  "Brownson Technical School",
  "Bucks County Community College",
  "Butler County Community College",
  "Byte Academy",
  "Calhoun Community College",
  "California Healing Arts College",
  "California State University",
  "Cambridge Dental, Inc.",
  "Cape Coral Technical College",
  "Capitol School",
  "Career & Technology Education Centers of Licking County-C-Tec",
  "Career Training Academy",
  "Carolina Code School",
  "Carson-Newman College",
  "Casper College",
  "CDA Technical Institute",
  "Center for Child Development and Developmental Disabilities",
  "Center for Natural Wellness School of Massage Therapy",
  "Central Career School",
  "Central New Mexico Community College",
  "Central Susquehanna",
  "Cephas Center for Health Sciences",
  "Chamberlain University",
  "Charles R. Drew University of Medicine & Science Nursing Program",
  "Chattanooga College",
  "Chester County Intermediate Unit EA",
  "Chippewa Valley Technical College",
  "Cincinnati State Technical & Community College",
  "Claim Academy",
  "Cleveland Clinic",
  "Clinton Public Schools",
  "CNA Online",
  "Code Fellows",
  "College of Court Reporting",
  "College of Lake County",
  "College of the Desert",
  "CollegeAmerica - Denver",
  "Colorado Christian University",
  "Colorado Media School",
  "Columbia College",
  "Columbus State University",
  "Commonsense Childbirth School of Midwifery",
  "Compass Career College",
  "Concord University",
  "Concorde Career Institute",
  "Construction Education Foundation of North Texas",
  "Coral Springs Regional Institute of Public Safety",
  "Covenant Medical Center - Covenant Health Systems",
  "Crane Care Operations",
  "Crane Solutions, LLC.",
  "CraneCoach",
  "Crowleys Ridge Technical Institute",
  "Cuesta College",
  "CyberTex, Inc.",
  "Dakota State University",
  "Danville Area Community College",
  "Davis Applied Technology College",
  "Daytona College",
  "Defense Information Systems Agency",
  "Delaware State Fire School",
  "Delta Technical College",
  "Des Moines Area Community College",
  "DeVry University",
  "Diman Regional Technical Institute",
  "Divers Institute of Technology",
  "DMC Sinai-Grace Hospital",
  "Driver Solutions",
  "Eagle Gate College",
  "East Valley Medical College",
  "Eastern Florida State College",
  "Eastern Suffolk BOCES",
  "Eastwick College - Nutley",
  "EDIC College",
  "El Centro College",
  "Eleven Fifty Academy",
  "Emergency Medicine Academy",
  "EMS Training Center of Southern NV",
  "Equine Gnathological Training Institute, Inc.",
  "Erwin Technical College",
  "ETI Technical College",
  "Evergreen Valley College",
  "Fairleigh Dickension University - PharmD",
  "Feather River College",
  "Finger Lakes Health College of Nursing",
  "Firehouse Medics",
  "First Response Training Group",
  "Florence - Darlington Technical College",
  "Florida Gateway College",
  "Florida International University",
  "FLT Academy",
  "Forsyth Technical Community College",
  "Fountainhead College Technology",
  "Franciscan Health Crown Point",
  "Froedtert & The Medical College of Winsconsin",
  "Frontier Nursing University",
  "FVTC",
  "Galen College Inc",
  "Gateway Community College",
  "Gemological Institute of America",
  "Genesis Dental Assistant School",
  "Georgia Northwestern Technical College",
  "Georgia Trade School",
  "Global Health College",
  "Golden Gate Language Schools",
  "Gould's Academy",
  "Grand Circus",
  "Great Plains Technology Center",
  "Greater Lowell Technical School",
  "Griffin's Industrial Welding School",
  "Gupton-Jones College of Funeral Service",
  "Hackbright Academy",
  "Hamilton Technical College",
  "Harford Community College",
  "Harrisburg Area Community College - Harrisburg Campus",
  "Hawaii Tokai International College",
  "Healing Oasis Wellness Center",
  "Health Works Institute",
  "Heavy Equipment Colleges of America",
  "Henry Ford Hospital",
  "Highland Community College",
  "Hobart Institute of Welding Technology",
  "Hollywood Institute of Beauty Careers",
  "Holy Names University",
  "Horry-Georgetown Technical College",
  "Hudson Valley Community College",
  "IA MED",
  "IE Safety Services, LLC.",
  "Illinois Media School",
  "Indian Capital Technology Center",
  "Indiana University School of Medicine",
  "Infotech Career College",
  "Institute of Audio Research",
  "Institute of Medical and Business Careers",
  "IntelliTec College - Albuquerque",
  "InterCoast Colleges",
  "International Culinary Center, LLC.",
  "International Yacht Restoration School",
  "Irene's Myomassology Institute",
  "ITI Technical College",
  "J F Ingram State Technical College",
  "Jefferson College",
  "Jefferson State Community College",
  "John Zink Institute",
  "Jones Technical Institute (J-Tech Institute)",
  "Kansas City Kansas Community College",
  "Kenai Peninsula Driving Instruction",
  "Kettering University",
  "Kino College",
  "L'Academie de Cuisine, Inc.",
  "Lab Four Professional Development Center",
  "Laguna Crane Services, LLC.",
  "Lake Technical College",
  "Lakeview College of Nursing",
  "Landmark Learning",
  "Language Systems International College of English",
  "Laredo College",
  "Launch Academy",
  "LeaderQuest Holdings",
  "Leech Lake Tribal College",
  "Lewis-Clark State College",
  "Lift Tech Crane and Rigging",
  "Lingua Language Center at Broward College",
  "Loma Linda University",
  "Long Island Business Institute",
  "Los Angeles Mission College",
  "Los Medanos College",
  "Lowery A. Woodall Advanced Technology Center",
  "Madison Adult Career Center",
  "Maine School of Masonry",
  "Marian University",
  "Martinsburg College",
  "Massachusetts General Hospital",
  "Massasoit Community College",
  "Maternidad La Luz",
  "McCann School of Business & Technology",
  "Med-Assist School of Hawaii",
  "Medical Career & Technical College",
  "Medical Institute of Northeast Georgia",
  "Medical Training College",
  "Mendocino College",
  "Mercy - St. Lukes Hospitals",
  "Mercy Medical Center",
  "Meridian Institute of Surgical Assisting",
  "Methodist College",
  "Metropolitan Community College",
  "Miami Dade College",
  "Michigan Barber School",
  "Mid-America Technology Center",
  "Midlands Technical College",
  "Milan Institute",
  "Milwaukee Area Technical College",
  "Minnesota Fire Service Certification Board",
  "Mission College",
  "Missouri University of Science and Technology",
  "Modern Technology School",
  "Monmouth County Vocational School District",
  "Monterey Peninsula College",
  "Moraine Park Technical College",
  "Moreno Valley College",
  "Mott Community College",
  "Mount Vernon Nazarene University",
  "Mountainland Technical College",
  "Mt. San Antonio College",
  "Murchison Drilling Schools , Inc.",
  "Napa Valley College",
  "Nassau Community College",
  "National Conservatory of Dramatic Arts",
  "National Park Community College",
  "National University of Health Sciences",
  "Navarro College",
  "Nevada Career Institute",
  "New America College",
  "New Horizons",
  "New York Automotive & Diesel Institute",
  "New York Medical College",
  "NHTI - Concord's Community College",
  "North American Lineman Training Center",
  "North Central Institute",
  "North Iowa Area Community College",
  "Northampton County Area Community College",
  "Northeast Wisconsin Technical College",
  "Northland Career Center",
  "Northwest HVAC/R Association & Training Center",
  "Northwest School of Wooden Boatbuilding",
  "Northwood University-Michigan",
  "NYC Data Science Academy",
  "Oakwood University",
  "Ogden-Weber Tech college",
  "Ohio State College of Barber Styling",
  "Ohlone College",
  "Oklahoma Technical College",
  "Operator Training & Inspection Services, LLC.",
  "Oregon Health & Science University",
  "Orleans Technical College",
  "Owens Community College",
  "Palm Beach Academy of Health & Beauty",
  "Paradise Valley Community College",
  "Patton State Hospital",
  "PCI Health Training Center",
  "Penn Commercial Business/Technical School",
  "Pennsylvania Gunsmith School",
  "Petroleum College International",
  "PIA",
  "Pike Peak Community College",
  "Pine Technical College",
  "Pitt Community College",
  "Platt College",
  "Pontotoc Technology Center",
  "Precision Manufacturing Institute",
  "Presentation College",
  "Prism Career Institute",
  "Professional Culinary Academy",
  "Providence Hospital",
  "Pure Aesthetics",
  "QuickStart Learning",
  "RedVector",
  "Reid State Technical College",
  "Renton Technical College",
  "Rigging Institute, LLC",
  "River Valley School of Massage",
  "Roberto-Venn School of Luthiery",
  "Rocky Mountain University of Health Professions",
  "Rose-Hulman Institute of Technology",
  "Ross Education, LLC",
  "Royal Academy of Surgical Assisting",
  "RWM Fiber Optics, Inc.",
  "SAE Institute of Technology Nashville",
  "Saint Elizabeth School of Nursing",
  "Salina Tech",
  "San Bernardino Valley College",
  "San Francisco State University",
  "Sanford Health",
  "Santa Rosa Junior College",
  "School of Automotive Machinists & Technology",
  "Sea Mar Community Health Center",
  "Seid Crane Services, Inc.",
  "Seven Soul Tattoo",
  "Shawsheen Valley Technical High School",
  "Shore Medical Center School of Radiologic Technology",
  "SJCOE",
  "Skyline College",
  "Snow College",
  "South Central Louisiana Technical College",
  "SOUTH DENVER SCHOOL OF NURSING ARTS",
  "South Plains College",
  "Southeast Health College of Nursing and Health Sciences",
  "Southeastern Institute",
  "Southern California Health Institute",
  "Southern Illinois University",
  "Southern Oklahoma Technology Center",
  "Southern Westchester BOCES",
  "Southwest Oklahoma State University",
  "Southwestern Community College - Sylva",
  "Sowela Technical Community College",
  "Specs Howard School of Media Arts",
  "St Charles Community College",
  "St. Charles Community College",
  "St. John's College of Nursing",
  "St. Luke's Hospital of Kansas City",
  "St. Paul's School of Nursing",
  "Star Truck Driving School",
  "Stein academy",
  "Stevens-Henager College - Ogden/West Haven",
  "Sullivan University",
  "Sunrock Dental Careers",
  "SUNY College of Technology at Delhi",
  "Swedish American Hospital",
  "Tadano",
  "Target International Student Center",
  "TDDS Technical Institute",
  "Technology Testing, Inc.",
  "Tennessee College of Applied Technology - Nashville",
  "Texas A&M University Health Science Centre",
  "Texas Healthtech Institute",
  "The Academy of Equine Dentistry",
  "The Cleveland Clinic Foundation",
  "The Commonwealth Medical College",
  "The Culinary School of Fort Worth",
  "The Fab School",
  "The Joe Kubert School of Cartoon and Graphic Art",
  "The Modern College of Design",
  "The Praxis Institute",
  "The Sanderson Group, Inc.",
  "The University of Kansas - PharmD",
  "The University of Toledo - PharmD Program",
  "Thomas Jefferson University",
  "Toni & Guy Hairdressing Academy",
  "Tri-County Allied Health School",
  "Triangle Tech",
  "Trinity Health System School of Nursing",
  "Truckee Meadows Community College",
  "Tulsa Welding School",
  "U.S. Ling Institute",
  "UEI College Ontario",
  "Ultimate Health School",
  "UNC School of Pharmacy",
  "Unitech Training Academy - Alexandria",
  "Unitek College",
  "Universal College of Healing Arts",
  "Universal Training Institute",
  "University of Alaska Fairbanks",
  "University of California , Riverside",
  "University of Connecticut",
  "University of Hawai'i",
  "University of Kansas Medical Center",
  "University of Massachusetts - Lowell",
  "University of New Haven",
  "University of Phoenix",
  "University of Southern Indiana",
  "University of the District of Columbia",
  "University of Virginia Health System",
  "UPMC",
  "UR Impact Academy",
  "Utah Fire and Rescue Academy",
  "Valley College",
  "Vantage Career Center",
  "Ventura College",
  "Victor Valley College",
  "Virginia College at Austin",
  "Virginia Marti College of Art & Design",
  "Vogue International Academy",
  "Waldorf University",
  "Washington County Career Center",
  "Welder Training and Testing Institute",
  "Wentworth Institute of Technology",
  "West Georgia Technical College",
  "West Penn Hospital School of Nursing",
  "WEST-MEC - WESTERN MARICOPA EDUCATION CENTER",
  "Western Nevada College",
  "Westmoreland County Community College",
  "Wilkes University - PharmD",
  "Winchester Medical Center",
  "Wisconsin Technoligical College",
  "Wor-Wic Community College",
  "Wyoming Catholic College",
  "Yavapai College",
];