import axios from '../utils/axiosSetup';

export const fetchSchoolsAndPrograms = async () => {
  const response = await axios.get('public/schools');
  const fetchedSchools = response.data.map(({id, logo, name, is_cobranded, custom_application_link}) => ({id, logo, name, is_cobranded, custom_application_link}));
  const fetchedPrograms = response.data.reduce((acc, current) => {
    acc[current.id] = current.programs.map((
      {id, name, default_length, down_payment_required, minimum_required_days_before_graduation, maximum_delay_until_start_date_in_days}
    ) => (
      {id, name, default_length, down_payment_required, minimum_required_days_before_graduation, maximum_delay_until_start_date_in_days}
    ));
    return acc;
  }, {});
  return { fetchedSchools, fetchedPrograms };
};

export const trackDisclosureEvent = async (eventType, schoolId, programId) => {
  const response = await axios.post('public/track_disclosure/cfba9c7e-7303-4e38-a287-b7195e951267', {
    event_type: eventType,
    school_id: schoolId,
    program_id: programId
  });
  return response.data;
};
