import React from 'react';
import { useLocation } from 'react-router-dom';
import FortifyIcon from './FortifyIcon';
const ComingSoon = () => {
  const location = useLocation();
  const school = new URLSearchParams(location.search).get('school') || 'that school';
  return (
    <div className="application-background">
      <div className="application-container text-center">
        <FortifyIcon />
        <h1 className="card-title"><b>Applications to {school} haven't opened yet.</b></h1>
        <p className="mb-4">
          If you're attending this school and would like a Fortify loan, please ask your school rep to reach out at partnerships@fortifyeducation.com.
        </p>
      </div>
    </div>
  );
};
export default ComingSoon;